import React from 'react'

import {getBrText} from '../../helpers/helper.rendering'
import { graphql, useStaticQuery } from 'gatsby';

    
import TICK from "../../images/svg/tick.svg";
const Admin = () => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            sections {
                  services{
                    management{
                        title
                        paragraph
                        items
                    }
                  }
            }
        }
    }`)

    return realEstate.sections && (
        <section class="tasaciones-administracion">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="section-name">{realEstate.sections.services.management.title}</p>
                    </div>
                    <div class="col-lg-10">
                        <h2>
                            {getBrText(realEstate.sections.services.management.paragraph).map((item, index) => (
                                <>
                                    {item}
                                    <br className="d-none d-lg-block" key={index} />
                                </>
                            ))}
                        </h2>
                    </div>
                    <div class="col-lg-10 offset-lg-2">
                        <div class="row">
                            {realEstate.sections.services.management.items.map((item, index) => (
                                item !== '' && 
                                <div class="col-lg-4 mb-5">
                                        <div class="item d-flex d-lg-block" key={index}>
                                            {/* <div class="tick mr-3 mr-lg-0">
                                                <TICK />
                                                <i className="icon-check"></i>
                                            </div> */}
                                            <p>
                                                {item}
                                                <br className="d-none d-lg-block" key={index} />
                                            </p>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Admin